'use client';

import ErrorComponent from '@unifyapps/carbon/components/AsyncView/ErrorComponent';
import type Error from 'next/error';
import { useEffect } from 'react';
import { SnackbarProvider } from '@unifyapps/ui/components/Snackbar';
import { logError } from '@unifyapps/carbon/utils/error-reporting/log';
import ThemeRegistry from '@/utils/theme/ThemeRegistry/ThemeRegistry';

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    logError(error);
  }, [error]);

  const host = typeof window !== 'undefined' ? window.location.origin : null;

  return (
    <html lang="en">
      <body className="h-screen">
        <SnackbarProvider>
          <ThemeRegistry host={host}>
            <ErrorComponent reset={reset} />
          </ThemeRegistry>
        </SnackbarProvider>
      </body>
    </html>
  );
}
