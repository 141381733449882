import './iconButton.css';
import { default as BaseIconButton } from '@mui/joy/IconButton';
import { forwardRef } from 'react';
import { clsx } from 'clsx';
import { buttonBaseVariants } from '../Button/styles';
import type { IconButtonProps } from './types';
import { iconButtonIconVariants, iconButtonVariants } from './styles';

const ICONBUTTON_ID_CLASSNAME = 'ua-iconButton';

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>((props, ref): JSX.Element => {
  const {
    variant,
    color,
    size,
    Icon,
    className,
    iconClassName: iconClassNameProp,
    ...rest
  } = props;

  const iconButtonClassName = clsx(
    ICONBUTTON_ID_CLASSNAME,
    buttonBaseVariants({
      color,
      variant,
    }),
    iconButtonVariants({
      size,
    }),
    className,
  );

  const iconClassName = iconButtonIconVariants({
    className: iconClassNameProp,
    size,
  });

  return (
    <BaseIconButton
      ref={ref}
      {...rest}
      className={iconButtonClassName}
      data-color={color}
      data-size={size}
      data-variant={variant}
    >
      <Icon className={iconClassName} />
    </BaseIconButton>
  );
});

IconButton.displayName = 'IconButton';

export default IconButton;
