// noinspection TypeScriptUnresolvedReference

import type * as Sentry from '@sentry/react';
import { captureException } from '@sentry/react';

export function logError(...args: Parameters<typeof Sentry.captureException>) {
  if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    captureException(...args);
  } else if (
    process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID &&
    process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN
  ) {
    void import('@datadog/browser-rum').then(({ datadogRum }) => datadogRum.addError(...args));
  } else {
    // local development
    console.error(...args);
  }
}
