'use client';
import type { EmptyStateProps } from '@unifyapps/ui/components/EmptyState';
import { EmptyState } from '@unifyapps/ui/components/EmptyState';
import SvgAlertTriangle from '@unifyapps/icons/outline/AlertTriangle';
import SvgRefreshCcw01 from '@unifyapps/icons/outline/RefreshCcw01';
import { useTranslation } from '@unifyapps/i18n/client';

export type ErrorComponentProps = Partial<EmptyStateProps> & {
  reset?: () => void;
  refresh?: () => void;
  refreshLabel?: string;
};

export default function ErrorComponent({
  reset,
  refresh,
  refreshLabel,
  ...props
}: ErrorComponentProps) {
  const { t } = useTranslation(['common']);

  const secondaryButton: EmptyStateProps['secondaryButton'] = reset
    ? {
        label: t('common:ErrorState.TryAgain'),
        onClick: reset,
        startDecoratorComponent: SvgRefreshCcw01,
      }
    : {
        // NODE: default behaviour of refresh is to reload the page, hence `Refresh Page`
        // but if refresh is provided, since it custom handling we show only `Refresh`
        label:
          refreshLabel ??
          (refresh ? t('common:ErrorState.Refresh') : t('common:ErrorState.RefreshPage')),
        onClick: refresh ?? (() => window.location.reload()),
        startDecoratorComponent: SvgRefreshCcw01,
      };

  return (
    <EmptyState
      Icon={SvgAlertTriangle}
      description="Failed to load. Please refresh and try again."
      secondaryButton={secondaryButton}
      size="sm"
      slotProps={{
        featuredIcon: {
          color: 'error',
        },
      }}
      title="Something went wrong"
      {...props}
    />
  );
}

export const renderedErrorComponent = <ErrorComponent />;
