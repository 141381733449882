import { cva } from 'class-variance-authority';
import type { IconButtonSizes } from './types';

export const iconButtonVariants = cva('', {
  variants: {
    size: {
      xs: 'size-6',
      sm: 'size-7',
      md: 'size-9',
      lg: 'size-12',
    } satisfies Record<IconButtonSizes, string>,
  },
});

export const iconButtonIconVariants = cva('shrink-0', {
  variants: {
    size: {
      xs: 'size-3',
      sm: 'size-4',
      md: 'size-5',
      lg: 'size-5',
    } satisfies Record<IconButtonSizes, string>,
  },
});
